<template>
  <div class="info-two">
    <van-cell-group title="工作资料">
      <van-field
        v-model="form.work_state"
        label="工作状况"
        @click="showSheet = true"
        placeholder="请选择"
        right-icon="arrow"
        readonly
        input-align="right"
      />
      <van-field
        v-if="form.work_state === '其它'"
        clearable
        type="textarea"
        rows="2"
        maxlength="100"
        autosize
        v-model="form.work_state_other"
        placeholder="请具体说明工作状况详情，100个字内"
      />
      <van-field
        v-model="form.master_name"
        label="雇主名称"
        input-align="right"
        maxlength="100"
        clearable
        v-if="showMasterMsg"
        placeholder="请输入"
      />
      <van-field
        v-model="form.position"
        label="职位"
        input-align="right"
        maxlength="100"
        clearable
        v-if="showMasterMsg"
        placeholder="请输入"
      />
      <van-field
        label-width="7em"
        v-model="form.industry"
        @click="showPopup = true"
        label="行业/业务性质"
        placeholder="请选择"
        v-if="showMasterMsg"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="form.work_year"
        label="受雇年期(年)"
        v-if="showMasterMsg"
        clearable
        maxlength="100"
        input-align="right"
        placeholder="请输入数值"
        :formatter="formatterWorkYear"
      />
      <van-field
        v-model="form.office_tel"
        v-if="showMasterMsg"
        input-align="right"
        maxlength="100"
        clearable
        label="办公电话"
        placeholder="请输入，格式如：+86-0755-22664061"
        :formatter="formatterOfficeTel"
      />
      <van-field
        v-model="form.office_addr"
        v-if="showMasterMsg"
        label="办公地址"
        maxlength="100"
        clearable
        input-align="right"
        placeholder="请输入"
      />
    </van-cell-group>
    <van-cell-group title="财务资料">
      <van-field
        clearable
        v-model="form.annual_salary"
        label="年薪 (港币)"
        maxlength="100"
        placeholder="请输入"
        input-align="right"
        type="number"
        :formatter="formatterFund"
      />
      <van-field
        label-width="7em"
        v-model="form.net_asset"
        clearable
        maxlength="100"
        input-align="right"
        label="资产净值(港币)"
        placeholder="请输入"
        type="number"
        :formatter="formatterFund"
      />
      <!-- <van-field v-model="form.value10" label="收入来源"/> -->
      <van-cell>
        <template #title>
          <span class="van-cell__title van-field__label">收入来源</span>
        </template>
        <template #extra>
        <van-checkbox-group class="check-group" v-model="form.income_source" >
            <van-checkbox name="薪金" shape="square">薪金</van-checkbox>
            <van-checkbox name="储蓄" shape="square">储蓄</van-checkbox>
            <van-checkbox name="租金" shape="square">租金</van-checkbox>
            <van-checkbox name="其它" shape="square">其它</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-cell>
      <van-field
        clearable
        v-model="form.other_income"
        label="其它收入来源"
        input-align="right"
        maxlength="100"
        v-if="showOtherIncome"
        placeholder="请输入"
      />
    </van-cell-group>
    <van-action-sheet
      v-model:show="showSheet"
      :actions="work_state"
      @select="onSheetSelect"
      maxlength="100"
      cancel-text="取消"
      close-on-click-action
    />
    <van-popup position="bottom" v-model:show="showPopup">
      <van-picker
        :columns="industry"
        @confirm="onPopupSelect"
        @cancel="showPopup = false"
      />
    </van-popup>
    <div style="width: 100%;text-align: center;">
      <van-button
        type="primary"
        class="btn"
        @click="next"
        :disabled="disabledNext || !form.work_state_other && form.work_state === '其它'"
      >
        下一步(投资目标及经验)
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  CellGroup,
  Field,
  ActionSheet,
  Popup,
  Picker,
  Cell,
  Checkbox,
  CheckboxGroup,
  NumberKeyboard,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted, watch, reactive, ref,
} from 'vue';
import httpApi from '../../utils/httpApi';

const work_state = [{ name: '受雇' }, { name: '自雇' }, { name: '家庭主妇' }, { name: '退休' }, { name: '其它' }];
const industry = [
  '能源',
  '化工',
  '材料',
  '机械设备/军工',
  '企业服务/造纸印刷',
  '运输设备',
  '旅游酒店',
  '媒体/信息通信服务',
  '批发/零售',
  '消费品',
  '卫生保健/医疗',
  '金融',
  '建材/建筑/房地产',
  '公用事业',
];
let submitForm;
export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [ActionSheet.name]: ActionSheet,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [NumberKeyboard.name]: NumberKeyboard,
  },
  data() {
    return {
      work_state,
      industry,
    };
  },
  methods: {
    formatterWorkYear(value) {
      const n = value.replace(/[^0-9]+/g, '').substr(0, 2);
      return Number(n) > 0 ? n : '';
    },
    formatterOfficeTel(value) {
      return value.replace(/[^0-9+-]+/g, '').substr(0, 20);
    },
    formatterFund(value) {
      const n = value.replace(/[^0-9]+/g, '').substr(0, 12);
      return Number(n) > 0 ? n : '';
    },
    async next() {
      if (this.disabledNext.value) return;
      const { code, msg } = await this.$api.postInfoStepTwo(submitForm);
      if (code === 200) {
        this.$router.push({ path: '/info/step_three' });
      } else {
        this.$toast(msg);
      }
    },
    before() {
      this.$router.go(-1);
    },
    onNumberInput(value) {
      const oldValue = this.form[this.showNumberKeybord];
      if (oldValue) {
        this.form[this.showNumberKeybord] = oldValue + value;
      } else {
        this.form[this.showNumberKeybord] = value;
      }
    },
    onNumberDelete() {
      const value = this.form[this.showNumberKeybord];
      if (value) {
        this.form[this.showNumberKeybord] = value.substr(0, value.length - 1);
      }
    },
  },
  setup() {
    const form = reactive({
      work_state: '',
      work_state_other: '',
      master_name: '',
      position: '',
      industry: '',
      work_year: '',
      office_tel: '',
      office_addr: '',
      annual_salary: '',
      net_asset: '',
      income_source: [],
      other_income: '',
    });
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '资料填写');
      store.commit('setStepsValue', ['填写资料(2/5)', '风险认知', '提交资料']);
      store.commit('setStepsActive', 0);
      const result = await httpApi.getInfoStepTwo();
      if (!result) return;
      Object.getOwnPropertyNames(form).forEach((item) => {
        if (result.data[item]) {
          form[item] = result.data[item];
        }
      });
    });
    const showSheet = ref(false);
    const onSheetSelect = (value) => {
      showSheet.value = false;
      form.work_state = value.name;
    };
    const showPopup = ref(false);
    const onPopupSelect = (value) => {
      showPopup.value = false;
      form.industry = value;
    };

    const showOtherIncome = ref(false);
    const showMasterMsg = ref(false);
    const disabledNext = ref(true);
    watch(form, (val) => {
      submitForm = { ...val };

      showOtherIncome.value = true;
      if (submitForm.income_source.indexOf('其它') === -1) {
        showOtherIncome.value = false;
        delete submitForm.other_income;
      }

      showMasterMsg.value = true;
      const work_type = ['受雇', '自雇'];
      if (work_type.indexOf(submitForm.work_state) === -1) {
        showMasterMsg.value = false;
        delete submitForm.master_name;
        delete submitForm.position;
        delete submitForm.industry;
        delete submitForm.work_year;
        delete submitForm.office_tel;
        delete submitForm.office_addr;
      }

      if (submitForm.work_state !== '其它') {
        delete submitForm.work_state_other;
      }

      disabledNext.value = false;
      Object.getOwnPropertyNames(submitForm).forEach((item) => {
        if (item === 'income_source') {
          submitForm[item] = [...submitForm[item]];
          if (submitForm[item].length === 0) disabledNext.value = true;
        } else if (!submitForm[item].trim()) {
          disabledNext.value = true;
        }
      });
      httpApi.formLog(submitForm, disabledNext);
    });
    return {
      form,
      showSheet,
      onSheetSelect,
      showPopup,
      showOtherIncome,
      onPopupSelect,
      showMasterMsg,
      disabledNext,
    };
  },
};
</script>

<style lang="less">
  .info-two {
    text-align: left;
    .check-group {
      width: 200px;
      height: 60px;
      display: flex;
      justify-content: space-around;
      align-items: space-around;
      flex-wrap: wrap;
      & > div {
        flex-basis: 50%;
      }
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
